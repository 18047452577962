import React, { useState } from 'react';
import { CommandButton, Toggle } from 'office-ui-fabric-react';
import styles from './Settings.module.scss';
import { IconWithCallout } from '../atoms/IconWithCallout';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { IMembers, ISurvey } from '../../store/business/interfaces';
import { LogoUploader } from '../molecules/LogoUploader';

export interface PropsSettings {
	survey: ISurvey;
	onClose: () => void;
	readOnly?: boolean;
	members: IMembers;
	onUpsertSurvey: (survey: ISurvey) => void;
}

// declare class ClipboardItem {
// 	constructor(input: { [contentType: string]: Blob });
// }
/**
 *
 * @param props see PropSettings
 * @returns "Sitzung verwalten" inkl. LogoUploader
 */
export const Settings = (props: PropsSettings) => {
	const questions = useSelector((state: AppState) => state.business.questions);
	const [useQuickVotes, setUseQuickVotes] = useState(props.survey.useQuickVotes);
	const [useMsal, setUseMsal] = useState(props.survey.useMsal);
	const [disableResultsInMemberView, setDisableResultsInMemberView] = useState(props.survey.disableResultsInMemberView);
	const [sortResultsByVotes, setSortResultsByVotes] = useState(props.survey.sortResultsByVotes);
	const [showResultsAsPie, setShowResultsAsPie] = useState(props.survey.showResultsAsPie);
	const [members] = useState(props.members.entities);

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Sitzung verwalten</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onClose();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<div>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Schnelle Abstimmungen'
						checked={useQuickVotes}
						disabled={props.readOnly || questions?.length > 0 || props.survey.useVoteWeight || members?.length > 0 || useMsal}
						onChange={(evt, newUseQuickVotes) => {
							setUseQuickVotes(newUseQuickVotes);
							props.onUpsertSurvey({
								...props.survey,
								useQuickVotes: newUseQuickVotes,
								useVoteWeight: false,
								useVoteWeightInPercent: false,
								useVoteWeightWithDecimals: false,
							});
						}}
					></Toggle>

					{!props.readOnly && (
						<IconWithCallout
							iconName='Info'
							renderCalloutContent={() => {
								return (
									<span>
										Teilnehmer können sich ohne PIN anmelden. Die Teilnehmerliste wird nach der Anmeldung automatisch
										befüllt. Bei einer aktivierten Stimmengewichtung sind keine schnellen Abstimmungen möglich.
									</span>
								);
							}}
							iconContainerClassName='votrInfoIcon marginBottom8'
						/>
					)}
				</div>

							{/* Toggle for Microsoft Login */}

				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Microsoft Login verwenden'
						checked={useMsal}
						disabled={props.readOnly || questions?.length > 0 || props.survey.useVoteWeight || members?.length > 0 || props.survey.useQuickVotes}
						onChange={(evt, newUseMsal) => {
							setUseMsal(newUseMsal);
							props.onUpsertSurvey({ ...props.survey, useMsal: newUseMsal, useVoteWeight: false, useVoteWeightInPercent: false, useVoteWeightWithDecimals: false });
						}}
					></Toggle>
				</div>



				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Ergebnisse nur Organisatoren anzeigen'
						checked={disableResultsInMemberView}
						disabled={props.readOnly}
						onChange={(evt, newDisableResultsInMemberView) => {
							setDisableResultsInMemberView(newDisableResultsInMemberView);
							props.onUpsertSurvey({ ...props.survey, disableResultsInMemberView: newDisableResultsInMemberView });
						}}
					></Toggle>
				</div>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Ergebnisse nach Stimmen sortieren'
						checked={sortResultsByVotes}
						disabled={props.readOnly}
						onChange={(evt, newSortResultsByVotes) => {
							setSortResultsByVotes(newSortResultsByVotes);
							props.onUpsertSurvey({ ...props.survey, sortResultsByVotes: newSortResultsByVotes });
						}}
					></Toggle>
				</div>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Ergebnisse als Kreisdiagram anzeigen (Auswahl)'
						checked={showResultsAsPie}
						disabled={props.readOnly}
						onChange={(evt, newShowResultsAsPie) => {
							setShowResultsAsPie(newShowResultsAsPie);
							props.onUpsertSurvey({ ...props.survey, showResultsAsPie: newShowResultsAsPie });
						}}
					></Toggle>
				</div>
				<LogoUploader />
			</div>
		</div>
	);
};
