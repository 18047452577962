import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import styles from './Messages.module.scss';
import { IMessage, MessageType } from '../../notifications';
export interface IProps {
	messages?: IMessage[];
	onRemoveMessage: (index: number) => void;
}

export const Messages = (props: IProps) => {
	const [timer, setTimer] = useState(0);

	useEffect(() => {
		if (timer === 0) {
			setTimer(
				window.setInterval(() => {
					const past = new Date(new Date().getTime() - 5 * 1000);
					const messageIndex = props.messages?.findIndex((m) => m.created < past && !m.sticky);
					if (messageIndex > -1) {
						props.onRemoveMessage(messageIndex);
					}
				}, 5000)
			);
		}
		return () => {
			if (timer > 0) {
				window.clearInterval(timer);
				setTimer(0);
			}
		};
	}, [timer, props]);

	const renderMessage = (message: IMessage, index: number) => {
		let messageBarType: MessageBarType = MessageBarType.info;
		let messageText = '';
		if (!message) {
			return;
		}
		switch (message.type) {
			case MessageType.ERROR:
				messageBarType = MessageBarType.error;
				break;
			case MessageType.SUCCESS:
				messageBarType = MessageBarType.success;
				break;
			case MessageType.WARN:
				messageBarType = MessageBarType.warning;
				break;
			default:
				break;
		}
		if (!messageText) {
			messageText = message.message || '';
		}
		if (!messageText) {
			return;
		}
		return (
			<MessageBar
				key={index}
				messageBarType={messageBarType}
				messageBarIconProps={messageBarType === MessageBarType.error && { iconName: 'Error' }}
				isMultiline={true}
				onClick={() => props.onRemoveMessage(index)}
				dismissButtonAriaLabel='Schließen'
				onDismiss={() => props.onRemoveMessage(index)}
			>
				{messageText}
			</MessageBar>
		);
	};

	return <div className={styles.messages}>{props.messages?.map((m, i) => renderMessage(m, i))}</div>;
};
