import React, { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';

import { QuestionHead } from '../../molecules/QuestionForm/QuestionHead';
import { SecurityToggle } from '../../molecules/QuestionForm/SecurityToggle';
import { MultipleAnswerOptionsToggle } from '../../molecules/QuestionForm/MultipleAnswerOptionsToggle';
import { LimitAnswerOptionsToggle } from '../../molecules/QuestionForm/LimitAnswerOptionsToggle';
import { EnableVotesSplittingToggle } from '../../molecules/QuestionForm/EnableVotesSplittingToggle';
import { AnswerOptionsList } from '../../molecules/QuestionForm/AnswerOptionsList';

import { AppState } from '../../../store';
import { IQuestion, IAnswerOption } from '../../../store/business/interfaces';
import { createGuid } from '../../../shared/utils';
import { QuestionService } from '../../../services/QuestionService';

import styles from '../../molecules/Sidepanel.module.scss'; // TODO: use own module.scss
import { useScrollToAriaInvalid } from '../../../hooks';
import { fixVotesPerMember } from './helper';
import { TimedQestionToggle } from 'src/components/molecules/QuestionForm/TimedQuestionToggle';
import { DateTimeRangePicker } from 'src/components/atoms/DateTimeRangePicker';

export interface Props {
	question: IQuestion;
	disabled: boolean;
	setQuestion: Dispatch<SetStateAction<IQuestion>>;
	onSave: (question: IQuestion) => void;
	onCancel: () => void;
}

export const ChoiceQuestionForm = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const members = useSelector((state: AppState) => state.business.members);

	const [limitVotesPerMember, setLimitVotesPerMember] = useState(
		props.question.votesPerMember !== 1 && props.question.votesPerMember < props.question.answerOptions.length
	);
	const [answerOptions, setAnswerOptions] = useState<IAnswerOption[]>(() => {
		if (props.question.answerOptions.length === 0) {
			return [
				{ id: createGuid(), title: 'Antwort 1', attendees: [], votesCount: 0, type: 'answer' },
				{ id: createGuid(), title: 'Antwort 2', attendees: [], votesCount: 0, type: 'answer' },
			];
		}
		return props.question.answerOptions;
	});
	const [valid, setValid] = useState<boolean | undefined>(undefined);
	const [datesValid, setDatesValid] = useState<boolean>();
	const [scrollToInvalid] = useScrollToAriaInvalid();

	const enableVotesSplitting = !!members.entities.find((m) => m.voteWeight > 1) && !survey.useVoteWeightInPercent && !survey.useVoteWeightWithDecimals;

	const propsQuestion = props.question;
	const propsSetQuestion = props.setQuestion;
	const propsOnSave = props.onSave;

	useEffect(() => {
		propsSetQuestion((q) => {
			return fixVotesPerMember(q, answerOptions);
		});
	}, [answerOptions, propsQuestion, propsSetQuestion]);

	const validateDates = (isValid: boolean) => {
		setDatesValid(isValid);
	};

	const formValid =
		propsQuestion.title > '' && answerOptions.length > 0 && (propsQuestion.isTimerTriggered ? datesValid : true);

	const onClickSave = useCallback(() => {
		if (formValid) {
			const question: IQuestion = {
				...QuestionService.emptyQuestion(),
				...propsQuestion,
				attendees: [],
				loaded: undefined,
				answerOptions,
				votesPerMember:
					propsQuestion.votesPerMember !== 1
						? limitVotesPerMember
							? propsQuestion.votesPerMember
							: answerOptions.length
						: 1, // TODO: make clearer, nobody can read this
			};
			propsOnSave(question);
		} else {
			setValid(false);
			scrollToInvalid();
		}
	}, [propsQuestion, formValid, answerOptions, propsOnSave, scrollToInvalid, limitVotesPerMember]);

	return (
		<div className={styles.formContainer}>
			<QuestionHead
				question={props.question}
				disabled={props.disabled}
				showError={valid === undefined ? undefined : !valid}
				setQuestion={props.setQuestion}
				onCancel={props.onCancel}
			/>
			<TimedQestionToggle
				checked={props.question.isTimerTriggered}
				disabled={props.disabled}
				onChanged={(checked) => props.setQuestion({ ...props.question, isTimerTriggered: checked })}
			/>
			<DateTimeRangePicker
				startDate={props.question.startTime ?? null}
				stopDate={props.question.stopTime ?? null}
				question={props.question}
				visible={props.question.isTimerTriggered}
				onDatesChange={validateDates}
			/>
			<SecurityToggle
				checked={props.question.security === 'private'}
				disabled={props.disabled}
				onChanged={(checked) => props.setQuestion({ ...props.question, security: checked ? 'private' : 'public' })}
			/>
			<MultipleAnswerOptionsToggle
				checked={props.question.votesPerMember !== 1}
				disabled={props.disabled}
				visible={!survey.useVoteWeightInPercent}
				onChanged={(checked) => props.setQuestion({ ...props.question, votesPerMember: checked ? 2 : 1 })}
			/>
			<LimitAnswerOptionsToggle
				checked={limitVotesPerMember}
				disabled={props.disabled}
				visible={props.question.votesPerMember !== 1}
				maxAnswerOptionCount={answerOptions.length}
				answerOptionCount={props.question.votesPerMember}
				type='answers'
				onToggleChanged={setLimitVotesPerMember}
				onMaxAnswerOptionCountChange={(maxAnswer) =>
					props.setQuestion({ ...props.question, votesPerMember: maxAnswer })
				}
			/>
			<EnableVotesSplittingToggle
				checked={props.question.allowVotesSplitting}
				disabled={props.disabled}
				visible={enableVotesSplitting}
				onChanged={(checked) => props.setQuestion({ ...props.question, allowVotesSplitting: checked })}
			/>
			<AnswerOptionsList
				answerOptions={answerOptions}
				disabled={props.disabled}
				onChange={setAnswerOptions}
				type={'answers'}
			/>

			<div className={styles.buttonContainer}>
				<DefaultButton text='Abbrechen' onClick={() => props.onCancel()} />
				<PrimaryButton text='Speichern' onClick={onClickSave} disabled={props.disabled || !formValid} />
			</div>
		</div>
	);
};
