import React, { ReactNode } from 'react';
import styles from './LoadingOverlay.module.scss';
import { PulseLoader } from 'react-spinners';
import { BusinessDataType } from '../../store/business/enums';
import { SystemLoading } from '../../store/system/enums';
import { Modal } from 'office-ui-fabric-react';
import { AppState } from 'src/store';
import { useSelector } from 'react-redux';

export const useLoading = (excludeSystem?: SystemLoading[], excludeBusiness?: BusinessDataType[]): boolean => {
	const systemLoadingState = useSelector((state: AppState) => state.system.loading);
	const businessLoadingState = useSelector((state: AppState) => state.business.loading);

	const systemExcludeLoading = excludeSystem || [];
	const businessExcludeLoading = excludeBusiness || [];

	const businessLoading = Object.keys(businessLoadingState).reduce((agg, l) => {
		if (businessExcludeLoading.indexOf(l as BusinessDataType) > -1) return agg;
		return businessLoadingState[l as BusinessDataType] || agg;
	}, false);
	if(businessLoading) return true;

	const systemLoading = Object.keys(systemLoadingState).reduce((agg, l) => {
		if (systemExcludeLoading.indexOf(l as SystemLoading) > -1) return agg;
		return systemLoadingState[l as SystemLoading] || agg;
	}, false);
	return systemLoading;
};

export interface Props {
	children?: ReactNode;
	excludeBusiness?: BusinessDataType[];
	excludeSystem?: SystemLoading[];
};

export const LoadingOverlay = (props: Props) => {
	const loading = useLoading(props.excludeSystem, props.excludeBusiness);

	return (
		<div>
			{props.children}
			{loading && (
				<Modal isOpen={loading} isBlocking={true}>
					<div className={styles.loading}>
						<div className={styles.frame}>
							<PulseLoader size={15} margin={5} color={'#0f73ba'} />
							<span className={styles.text}>Bitte warten</span>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};