import { store } from '../store';
import { SystemLoading } from '../store/system/enums';
import { systemLoading, configLoaded, tokenLoaded, loggedIn, errorSet, tokenUpdated } from '../store/system/actions';
import { xhr } from '../xhr';
import { TokenService } from './TokenService';

export class SystemService {
	public static async getConfig(): Promise<any> {
		const response = await xhr(`api/v1/Config`, {
			method: 'GET',
			onError: (er) => {
				store.dispatch(errorSet(er.response?.data || er.toString(), er.response?.status));
				return Promise.reject();
			},
		});
		return response.data;
	}

	public static async ensureConfig(): Promise<void> {
		const { system } = store.getState();
		if (system.title !== undefined || system.loading[SystemLoading.Config]) {
			return;
		}
		store.dispatch(configLoaded(null, null, null, null));
		store.dispatch(systemLoading(SystemLoading.Config, true));
		try {
			const response = await xhr(`System/Config`, {
				method: 'GET',
				onError: (er) => {
					store.dispatch(errorSet(er.response?.data || er.toString(), er.response?.status));
					return Promise.reject();
				},
			});
			store.dispatch(configLoaded(response.data['title'], response.data['version'], response.data['appClientId'], response.data['tenantShortGuid']));
		} finally {
			store.dispatch(systemLoading(SystemLoading.Config, false));
		}
	}

	public static async login(): Promise<void> {
		const { system } = store.getState();
		if (system.apiKey !== undefined || system.loading[SystemLoading.Login]) {
			return;
		}
		store.dispatch(loggedIn(null));
		store.dispatch(systemLoading(SystemLoading.Login, true));
		try {
			const response = await xhr(`System/Login`, {
				method: 'GET',
				onError: (er) => {
					store.dispatch(errorSet(er.response?.data || er.toString(), er.response?.status));
					return Promise.reject();
				},
			});
			store.dispatch(loggedIn(response.data['apiKey']));
		} finally {
			store.dispatch(systemLoading(SystemLoading.Login, false));
		}
	}

	public static async ensureToken(clientId: string, force?: boolean): Promise<string> {
		const { system } = store.getState();
		if (system.context && (system.token === undefined || force)) {
			store.dispatch(tokenLoaded('', ''));
			try {
				const tokenSvc = TokenService.getInstance();
				const token = await tokenSvc.getAccessToken();
				store.dispatch(tokenLoaded(token, system.context.groupId));
				return token;
			} catch (ex) {
				store.dispatch(errorSet(ex));
			}
		}
		return '';
	}

	public static async updateToken() {
		const { system } = store.getState();
		if (system.context) {
			try {
				const token = await TokenService.getInstance().getAccessToken();
				if (token !== system.token) {
					store.dispatch(tokenUpdated(token));
				}
				return token;
			} catch (ex) {
				store.dispatch(errorSet(ex));
			}
		}
	}
}
