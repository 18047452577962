import React, { useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton, DefaultButton, TextField } from 'office-ui-fabric-react';

import { QuestionHead } from '../../molecules/QuestionForm/QuestionHead';
import { SecurityToggle } from '../../molecules/QuestionForm/SecurityToggle';
import { AnswerOptionsList } from '../../molecules/QuestionForm/AnswerOptionsList';

import { AppState } from '../../../store';
import { IQuestion, IAnswerOption } from '../../../store/business/interfaces';
import { createGuid } from '../../../shared/utils';
import { QuestionService } from '../../../services/QuestionService';
import ratingstyles from './RatingQuestionForm.module.scss';
import styles from '../../molecules/Sidepanel.module.scss';
import { useTranslation } from 'react-i18next';
import { EnableVotesSplittingToggle } from '../../molecules/QuestionForm/EnableVotesSplittingToggle';
import { useScrollToAriaInvalid } from 'src/hooks';
import { TimedQestionToggle } from 'src/components/molecules/QuestionForm/TimedQuestionToggle';
import { DateTimeRangePicker } from 'src/components/atoms/DateTimeRangePicker';

export interface RatingFormProps {
	question: IQuestion;
	disabled: boolean;
	setQuestion: Dispatch<SetStateAction<IQuestion>>;
	onSave: (question: IQuestion) => void;
	onCancel: () => void;
}

/**
 * Form to create new question of type 'rating', handles User Input.
 * saves settings of new rating-question (maxRating, ratingOptions)
 * calls QuestionService.emptyQuestion() and updates new attributes,
 * sets allowVoteSplitting-boolean
 * @param props {
    question: IQuestion;
    disabled: boolean;
    setQuestion: Dispatch<SetStateAction<IQuestion>>;
    onSave: (question: IQuestion) => void;
    onCancel: () => void;
}
 * @returns QuestionHead, SecurityToggle, TextField, EnableVotesSplittingToggle, AnswerOptionsList, Cancel-Button, Save-Button
 */
export const RatingQuestionForm = (props: RatingFormProps) => {
	const { t } = useTranslation();
	const survey = useSelector((state: AppState) => state.business.survey);
	const members = useSelector((state: AppState) => state.business.members);
	const enableVotesSplitting = !!members.entities.find((m) => m.voteWeight > 1) && !survey.useVoteWeightInPercent && !survey.useVoteWeightWithDecimals;
	const [maxRating, setMaxRating] = useState<any>(props.question.maxRating >= 5 ? props.question.maxRating : 5);
	const [valid, setValid] = useState<boolean | undefined>(undefined);
	const [datesValid, setDatesValid] = useState<boolean>();
	const [scrollToInvalid] = useScrollToAriaInvalid();
	const [ratingOptions, setRatingOptions] = useState<IAnswerOption[]>(() => {
		if (props.question.answerOptions.length === 0) {
			return [
				{ id: createGuid(), title: 'Antwort 1', attendees: [], votesCount: 0, type: 'answer' },
				{ id: createGuid(), title: 'Antwort 2', attendees: [], votesCount: 0, type: 'answer' },
			];
		}
		return props.question.answerOptions;
	});

	//setting value boundaries for min/max-rating in textfield
	const min = 5;
	const max = 10;

	const propsQuestion = props.question;
	const validateDates = (isValid: boolean) => {
		setDatesValid(isValid);
	};

	const formValid =
		propsQuestion.title > '' && ratingOptions.length > 0 && (propsQuestion.isTimerTriggered ? datesValid : true);
	const onClickSave = () => {
		const { id, title, description, kind, security, status, eTag } = props.question;
		if (formValid) {
			const question = {
				...QuestionService.emptyQuestion(),
				id,
				title,
				description,
				kind,
				security,
				attendees: [],
				status,
				eTag,
				loaded: undefined,
				maxRating: maxRating,
			};

			question.allowVotesSplitting = props.question.allowVotesSplitting;
			question.answerOptions = ratingOptions;
			props.onSave(question);
		} else {
			setValid(false);
			scrollToInvalid();
		}
	};

	return (
		<>
			<div className={styles.formContainer} data-testid='formContainer'>
				<QuestionHead
					question={props.question}
					disabled={props.disabled}
					showError={valid === undefined ? undefined : !valid}
					setQuestion={props.setQuestion}
					onCancel={props.onCancel}
				/>
				<TimedQestionToggle
					checked={props.question.isTimerTriggered}
					disabled={props.disabled}
					onChanged={(checked) => props.setQuestion({ ...props.question, isTimerTriggered: checked })}
				/>
				<DateTimeRangePicker
					startDate={props.question.startTime ?? null}
					stopDate={props.question.stopTime ?? null}
					question={props.question}
					visible={props.question.isTimerTriggered}
					onDatesChange={validateDates}
				/>
				<SecurityToggle
					checked={props.question.security === 'private'}
					disabled={props.disabled}
					onChanged={(checked) => props.setQuestion({ ...props.question, security: checked ? 'private' : 'public' })}
				/>

				<TextField
					className={ratingstyles.ratingInputField}
					type='number'
					min={min}
					max={max}
					value={maxRating}
					onChange={(e, v: string) => {
						let value = parseInt(v);
						setMaxRating(value > max ? max : value < min ? min : value);
					}}
					onRenderSuffix={() => <label>{t('answerOptionList.rating.ratingQuestionForm')}</label>}
				/>

				<EnableVotesSplittingToggle
					checked={props.question.allowVotesSplitting}
					disabled={props.disabled}
					visible={enableVotesSplitting}
					onChanged={(checked) => props.setQuestion({ ...props.question, allowVotesSplitting: checked })}
				/>
				<AnswerOptionsList
					answerOptions={ratingOptions}
					disabled={props.disabled}
					onChange={setRatingOptions}
					type={'answers'}
				/>

				<div className={styles.buttonContainer}>
					<DefaultButton data-testid='cancelButton' text='Abbrechen' onClick={() => props.onCancel()} />
					<PrimaryButton
						data-testid='saveButton'
						text='Speichern'
						onClick={onClickSave}
						disabled={props.disabled || !formValid}
					/>
				</div>
			</div>
		</>
	);
};
